import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchGalleries(_, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/aboutGalleryList`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGallery(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/aboutGalleryById', { params: { id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addGallery(_, card) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/aboutGalleryCreate', card)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateGallery(_, card) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/aboutGalleryUpdate', card)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteGallery(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/aboutGalleryDelete', { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImage(_, images) {
      return new Promise((resolve, reject) => {
        axios
          .post('/uploader', images, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    swapPosition(_, { oldPosition, newPosition}) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/movePosition/aboutGallery', { old: oldPosition, new: newPosition })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
